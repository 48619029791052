<template>
  <div>
    <b-row>

      <b-col cols="12">
        <b-card-actions
          title="Dados"
          action-collapse
        >
          <b-row>
            <b-col cols="6">
              <dl class="row">
                <dt class="col-sm-4">
                  ID
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.id }}
                </dd>
                <dt class="col-sm-4">
                  Estratégia
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.estrategia }}
                </dd>
                <dt class="col-sm-4">
                  Status
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.statusExecucao }}
                </dd>
                <dt class="col-sm-4">
                  Relatório
                </dt>
                <dd class="col-sm-8">
                  <router-link :to="{ name: 'backtester-backtest-relatorio', params: { id: backtest.id } }">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="backtest.statusExecucao != 'CONCLUIDO'"
                      variant="primary"
                      href="https://pixinvent.com/"
                      size="sm"
                    >
                      {{ backtest.statusExecucao == 'CONCLUIDO' ? 'Visualizar' : 'Indisponível' }}
                    </b-button>
                  </router-link>
                </dd>
              </dl>
            </b-col>
            <b-col cols="6">
              <dl class="row">
                <dt class="col-sm-4">
                  Criação
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.dataCriacao }}
                </dd>
                <dt class="col-sm-4">
                  Início BT
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.dataInicioBT }}
                </dd>
                <dt class="col-sm-4">
                  Fim BT
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.dataFimBT }}
                </dd>
                <dt class="col-sm-4">
                  Início Exec
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.dataInicioExec }}
                </dd>
                <dt class="col-sm-4">
                  Fim Exec
                </dt>
                <dd class="col-sm-8">
                  {{ backtest.dataFimExec }}
                </dd>
              </dl>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>

      <b-col
        xl="5"
        md="5"
      >
        <b-card-actions
          title="Controles"
          action-collapse
        >
          <div class="d-flex flex-wrap">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="disabledControl('EXECUTAR')? 'flat-secundary': 'flat-primary'"
              class="icon-card cursor-pointer text-center mx-50"
              :disabled="disabledControl('EXECUTAR')"
              @click="confirmarExecutar(backtest.id)"
            >
              <div class="icon-wrapper">
                <feather-icon
                  icon="PlayIcon"
                />
              </div>
              <p class="card-text icon-name text-truncate mb-0 mt-2">
                {{ backtest.statusExecucao == 'NAO_INICIADO'? 'Executar': 'Re-executar' }}
              </p>
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="disabledControl('VELOCIDADE')? 'flat-secundary': 'flat-primary'"
              class="icon-card cursor-pointer text-center mx-50"
              :disabled="disabledControl('VELOCIDADE')"
            >
              <div class="icon-wrapper">
                <vue-slider
                  v-model="controle.velocidade"
                  :disabled="disabledControl('VELOCIDADE')"
                  @change="onSliderChangeVelocidade(backtest.id, controle.velocidade)"
                />
              </div>
              <p class="card-text icon-name text-truncate mb-0 mt-2">
                Velocidade
              </p>
            </b-button>

            <b-button
              ref="pauseButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="disabledControl('PAUSAR')? 'flat-secundary': 'flat-primary'"
              class="icon-card cursor-pointer text-center mx-50"
              :disabled="disabledControl('PAUSAR')"
              @click.prevent="pausar(backtest.id, controle.pausa = !controle.pausa)"
            >
              <div class="icon-wrapper">
                <feather-icon
                  icon="PauseIcon"
                />
              </div>
              <p class="card-text icon-name text-truncate mb-0 mt-2">
                {{ controle.pausa? 'Pausado' : 'Pausar' }}
              </p>
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="disabledControl('ABORTAR')? 'flat-secundary': 'flat-primary'"
              class="icon-card cursor-pointer text-center mx-50"
              :disabled="disabledControl('ABORTAR')"
              @click="confirmarAbortar(backtest.id)"
            >
              <div class="icon-wrapper">
                <feather-icon
                  icon="StopCircleIcon"
                />
              </div>
              <p class="card-text icon-name text-truncate mb-0 mt-2">
                Abortar
              </p>
            </b-button>
          </div>
          <div class="demo-inline-spacing">
            <b-form-checkbox
              v-model="controle.modoVisual"
              plain
              :disabled="disabledControl('MODO_VISUAL')"
            >
              Modo visual
            </b-form-checkbox>
          </div>
        </b-card-actions>
      </b-col>
      <b-col
        xl="7"
        md="7"
      >
        <b-card-actions
          v-if="backtestWS.dashboardBTPairVisualDTO"
          title="Dasboard Pair"
          action-collapse
        >
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtestWS.dashboardBTPairVisualDTO.statusOperacao }}
                </h2>
                <span>Status</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.depositoInicial, 2, true) }}
                </h2>
                <span>Depósito Inicial</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.saldoAtual, 2, true) }}
                </h2>
                <span>Saldo Atual</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.capitalRestante, 2, true) }}
                </h2>
                <span>Capital Restante</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  <template v-if="backtestWS.dashboardBTPairVisualDTO.taxaAcerto">
                    {{ formataValor(backtestWS.dashboardBTPairVisualDTO.taxaAcerto, 2) }}%
                  </template>
                  <template v-else>
                    -
                  </template>
                </h2>
                <span>Taxa Acerto</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.payoff, 2) }}
                </h2>
                <span>Payoff</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.maiorLucroAberto, 2, true) }}
                </h2>
                <span>Maior Lucro Aberto</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.maiorPrejuizoAberto, 2, true) }}
                </h2>
                <span>Maior Prejuízo Aberto</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.pnlLiquidoTotal, 2, true) }}
                </h2>
                <span>PNL Líquido Total</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.custoTotal, 2, true) }}
                </h2>
                <span>Custo Total</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.margemTotal, 2, true) }}
                </h2>
                <span>Margem Total</span>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtestWS.dashboardBTPairVisualDTO.direcaoY? backtestWS.dashboardBTPairVisualDTO.direcaoY: '-' }}
                </h2>
                <span>Direcao Y</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.pnlLiquidoY, 2, true) }}
                </h2>
                <span>Pnl Líquido Y</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.custoY, 2, true) }}
                </h2>
                <span>Custo Y</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.margemY, 2, true) }}
                </h2>
                <span>Margem Y</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtestWS.dashboardBTPairVisualDTO.direcaoX? backtestWS.dashboardBTPairVisualDTO.direcaoX: '-' }}
                </h2>
                <span>Direcao X</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.pnlLiquidoX, 2, true) }}
                </h2>
                <span>Pnl Líquido X</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.custoX, 3, true) }}
                </h2>
                <span>Custo X</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTPairVisualDTO.margemX, 2, true) }}
                </h2>
                <span>Margem X</span>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="backtestWS.dashboardBTVisualDTO"
          title="Dasboard Pair"
          action-collapse
        >
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtestWS.dashboardBTVisualDTO.statusOperacao }}
                </h2>
                <span>Status</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.depositoInicial, 2, true) }}
                </h2>
                <span>Depósito Inicial</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.saldoAtual, 2, true) }}
                </h2>
                <span>Saldo Atual</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.capitalRestante, 2, true) }}
                </h2>
                <span>Capital Restante</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  <template v-if="backtestWS.dashboardBTVisualDTO.taxaAcerto">
                    {{ formataValor(backtestWS.dashboardBTVisualDTO.taxaAcerto, 2) }}%
                  </template>
                  <template v-else>
                    -
                  </template>
                </h2>
                <span>Taxa Acerto</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.payoff, 2) }}
                </h2>
                <span>Payoff</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtestWS.dashboardBTVisualDTO.direcao? backtestWS.dashboardBTVisualDTO.direcao: '-' }}
                </h2>
                <span>Direcao</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.maiorLucroAberto, 2, true) }}
                </h2>
                <span>Maior Lucro Aberto</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.maiorPrejuizoAberto, 2, true) }}
                </h2>
                <span>Maior Prejuízo Aberto</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.pnlLiquido, 2, true) }}
                </h2>
                <span>PNL Líquido</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.custo, 2, true) }}
                </h2>
                <span>Custo</span>
              </div>
            </b-col>

            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtestWS.dashboardBTVisualDTO.margem, 2, true) }}
                </h2>
                <span>Margem</span>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>

    <draggable
      v-model="backtestWS.graficos"
      class="row"
      @end="onDragEnd"
    >
      <b-col
        v-for="grafico in backtestWS.graficos"
        :key="grafico.id"
        cols="12"
      >
        <b-card-actions
          :title="grafico.nome"
          action-collapse
        >
          <div class="chart-container">
            <LWChart
              :grafico="grafico"
              :series="grafico.series"
              :series-update="grafico.series"
              :autosize="true"
            />
          </div>
        </b-card-actions>
      </b-col>
    </draggable>
  </div>
</template>
<script>
/* eslint-disable */

import {
  BRow, BCol, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { formatDateTimeBR } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LWChart from '@/layouts/components/custom/LWChart2.vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Stomp from "stompjs"
import backtesterStoreModule from './backtestStoreModule'
import debounce from "lodash.debounce";
import draggable from "vuedraggable";

const BACKTESTER_STORE_MODULE_NAME = 'backtest'

export default {

  components: {
    VueSlider,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BCardActions,
    LWChart,
    draggable,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatDateTimeBR,
      tableColumns: [
        { key: 'id', label: 'ID' },
        { key: 'ativoY', label: 'Ativo Y' },
        { key: 'ativoX', label: 'Ativo X' },
        { key: 'pnlTotalLiquido', label: 'Lucro líquido' },
        { key: 'corretagemTotal', label: 'Taxas' },
        { key: 'sinteticoEntrada', label: 'Ratio entrada' },
        { key: 'dataEntrada', label: 'Data entrada' },
        { key: 'acao', label: 'Ações' },
      ],
      backtest: [],
      controle: {
        velocidade: 0,
        modoVisual: true,
        pausa: false,
        aborta: false,
      },
      backtestWS: [],
      stompClient: null,
      ws: null,
    }
  },

  computed: {
    disabledControl ( ) {
      return controle => {
        return this.disabledControlFunc(controle)
      }
    }
  },

  watch: {
  },

  created() {
    if (!store.hasModule(BACKTESTER_STORE_MODULE_NAME)) store.registerModule(BACKTESTER_STORE_MODULE_NAME, backtesterStoreModule)
    this.getBacktest(this.$route.params.id)
  },

  beforeDestroy() {
    if (store.hasModule(BACKTESTER_STORE_MODULE_NAME)) store.unregisterModule(BACKTESTER_STORE_MODULE_NAME)
  },
  
  mounted() {
      window.addEventListener('keydown', this.handlePauseButton)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handlePauseButton)
  },

  methods: {

    handlePauseButton(event) {
      if (event.keyCode === 32) {
        event.preventDefault()
        const button = this.$refs.pauseButton
        button.click()
      }
    },

    disabledControlFunc (controle) {
      let desabilita = false
      switch (controle) {
        case 'EXECUTAR':
          desabilita = this.backtest.statusExecucao === 'EXECUTANDO'
          break

        case 'VELOCIDADE':
          break

        case 'PAUSAR':
          desabilita = this.backtest.statusExecucao === 'NAO_INICIADO' || this.backtest.statusExecucao === 'CONCLUIDO' || this.backtest.statusExecucao === 'ERRO' || this.backtest.statusExecucao === 'ABORTADO'
          break

        case 'ABORTAR':
          desabilita = this.backtest.statusExecucao === 'NAO_INICIADO' || this.backtest.statusExecucao === 'CONCLUIDO' || this.backtest.statusExecucao === 'ERRO' || this.backtest.statusExecucao === 'ABORTADO'
          break

        case 'MODO_VISUAL':
          break

      }

      return desabilita
    },

    onSliderChangeVelocidade: debounce(function (id, velocidade) {
      this.mudaVelocidade(id, velocidade);
    }, 500),


    mudaVelocidade(id, velocidade) {
      this.$store.dispatch('backtest/velocidade', { idBacktest: id, velocidade: velocidade })
        .then(() => {
          this.$bvToast.toast(`Nova velocidade: ${velocidade}`, {
            title: 'Velocidade alterada',
            autoHideDelay: 2000,
            appendToast: true,
            toaster: 'b-toaster-bottom-right',
            variant: 'primary',
            solid: true
          })
        })
    },

    pausar(id, isPausar) {
      this.$store.dispatch('backtest/pausar', { idBacktest: id, pausar: isPausar })
    },

    executar(id) {

      // deixa a velocidade no maximo quando não tem modo visual
      if (!this.controle.modoVisual) {
        this.controle.velocidade = 100
      }

      this.$store.dispatch('backtest/executar', { idBacktest: id, velocidade: this.controle.velocidade, modoVisual: this.controle.modoVisual })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Executando Backtest',
            text: 'Seu Backtest está sendo executado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    confirmarExecutar(id) {
      this.$swal({
        title: 'Tem certeza que deseja executar?',
        text: 'Seu backtest será executado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode executar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.executar(id)
        }
      })
    },

    abortar(id) {
      this.$store.dispatch('backtest/abortar', { idBacktest: id })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Abortando Backtest',
            text: 'Seu Backtest está sendo abortado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    confirmarAbortar(id) {
      this.$swal({
        title: 'Tem certeza que deseja abortar?',
        text: 'Seu backtest será abortado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode abortar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.abortar(id)
        }
      })
    },

    getBacktest(id) {
      this.$store.dispatch('backtest/getBacktest', id)
        .then(response => {
          this.backtest = response.data
          if(this.backtest.statusExecucao != 'NAO_INICIADO') {
            this.$store.dispatch('backtest/getControle', id)
              .then(r => {
                this.controle.pausa = r.data.pausa
                this.controle.velocidade = r.data.velocidade
                this.controle.aborta = r.data.aborta
              })
          }
          this.iniciaWs(id)
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Registro não encontrado',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    finalizaWs() {
      this.stompClient.disconnect()

    },

    iniciaWs(idBacktest) {
      const socket = new WebSocket('ws://localhost:8881/backtester/ws')
      socket.binaryType = 'arraybuffer';

      this.stompClient = Stomp.over(socket)
      const self = this

      this.stompClient.connect({}, () => {
        let isFirst = true
        this.stompClient.subscribe(`/info/${idBacktest}`, message => {
          const jsonWs = JSON.parse(message.body);
          if(isFirst){
            // removendo milisegundos do time
            jsonWs.graficos.forEach(g => {
                g.series.forEach(s => {
                  if (s.tipoSerie === 'CANDLESTICK' || s.tipoSerie === 'BAR') {
                    s.candlestickSerie.forEach(candle => {
                      candle.time =  candle.time / 1000
                    })
                  }else{
                    s.serieTemporal.forEach(serie => {
                      serie.time =  serie.time / 1000
                    })
                  }
                })
              })
            self.backtestWS =  jsonWs
            isFirst = false
          }else{
            // atualização

            jsonWs.graficos.forEach(newDataG => {
              const oldDataGIndex = self.backtestWS.graficos.findIndex(g => g.id === newDataG.id);
              if (oldDataGIndex >= 0) {
                const oldDataG = self.backtestWS.graficos[oldDataGIndex];
                newDataG.series.forEach(newDataS => {
                  if (newDataS.tipoSerie === "CANDLESTICK" || newDataS.tipoSerie === "BAR") {
                    newDataS.candlestickSerie.forEach(candle => {
                      candle.time = candle.time / 1000
                    })
                  } else {
                    newDataS.serieTemporal.forEach(serie => {
                      serie.time = serie.time / 1000
                    })
                  }

                  const oldDataSIndex = oldDataG.series.findIndex(s => s.nome === newDataS.nome);
                  if (oldDataSIndex >= 0) {
                    oldDataG.series.splice(oldDataSIndex, 1, newDataS);
                  } else {
                    oldDataG.series.push(newDataS);
                  }
                });
                self.backtestWS.graficos.splice(oldDataGIndex, 1, oldDataG);
              } else {
                self.backtestWS.graficos.push(newDataG);
              }
            });

          }
          self.backtest.statusExecucao = jsonWs.statusExecucaoBacktest
          self.backtestWS.dashboardBTPairVisualDTO = jsonWs.dashboardBTPairVisualDTO
          self.backtestWS.dashboardBTVisualDTO = jsonWs.dashboardBTVisualDTO

          if (self.backtest.statusExecucao == 'CONCLUIDO') {
            self.finalizaWs()
          }
        })
      })

    },

    onDragEnd() {
      // Update the order of the items array
      const newItems = [];
      const oldItems = this.backtestWS.graficos;
      for (let i = 0; i < oldItems.length; i++) {
        newItems.push(oldItems[i]);
      }
      this.backtestWS.graficos = newItems;
    },


    converterBase64ToJson(data) {
      const decodedStr = atob(data);
      console.log('rr: ' + decodedStr)
      return JSON.parse(decodedStr);
    },

    getDuracao(timestamp) {
      const date = new Date(timestamp)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${hours}h${minutes}min`
    },

    formataValor(value, limit, convertUSD) {
      if (value) {
        if (convertUSD) {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'USD' })
        }
        if (limit) {
          return parseFloat(value).toFixed(limit)
        }
        return value
      }
      return '-'
    },

  },

}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-slider.scss';
</style>
